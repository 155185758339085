
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import ItemService from '@/services/item-service';
import { OrdersService } from '@/services/orders-service';
import {
  Finish,
  Item,
  ItemDetails,
  ItemHistory,
  Material,
  Die,
  Color
} from '../../store/models/ItemModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import ItemActions from '@/components/ItemActions.vue';
import HiRezImg from '@/components/HiRezImg.vue';
import { DieTemplate } from '@/store/models/DieModel';
import { StringHelper } from '@/helpers/StringHelper';

@Component({
  components: { DefaultLayout, ItemActions, HiRezImg }
})
export default class extends Vue {
  /* Properties */
  itemId: string | null = null;
  tab: number = 0;
  imgDisplay: string = '';
  itemDetail: ItemDetails = new ItemDetails({
    Material: new Material(),
    Finish: new Finish(),
    Die: new Die(),
    Color: new Color()
  });
  itemHistory: ItemHistory[] = [];
  maskBuildType: string = 'trimmedlabel';
  itemInfos: any = [
    { id: 'PartNumber', text: 'Part Number', value: '', bold: true },
    { id: 'Description', text: 'Description', value: '', bold: true },
    {
      id: 'Status',
      text: 'Status',
      value: '',
      showStatus: true,
      bold: true,
      iconColor: ''
    },
    { id: 'Material', text: 'Material', value: '' },
    {
      id: 'Die',
      text: 'Die',
      value: ''
    },
    { id: 'Finish', text: 'Finish', value: '' },
    { id: 'Color', text: 'Color', value: '', showColor: true, colors: 0 },
    { id: 'LastUpdated', text: 'Last Updated', value: '' },
    { id: 'LastOrdered', text: 'Last Ordered', value: '' }
  ];
  itemHeader: any[] = [
    {
      text: 'Date time',
      align: 'start',
      sortable: true,
      value: 'EntryDateTime'
    },
    {
      text: 'Entry',
      align: 'start',
      sortable: false,
      value: 'Entry'
    },
    {
      text: 'User',
      align: 'start',
      sortable: false,
      value: 'UserName'
    }
  ];
  filterOptions: any[] = [
    {
      id: 0,
      text: 'Trimed label',
      value: 'trimedlabel',
      isSelected: true
    },
    {
      id: 1,
      text: 'Full bleed',
      value: 'fullbleed',
      isSelected: false
    },
    {
      id: 2,
      text: 'Safe zone',
      value: 'safezone',
      isSelected: false
    }
  ];
  colors: string[] = ['rgb(102,102,102)', 'blue', 'magenta', 'yellow', 'white', 'white'];
  colorIcons: string[] = [
    'looks_one',
    'looks_two',
    'looks_3',
    'looks_4',
    'looks_5',
    'looks_6'
  ];
  orderActivityHeader: any[] = [
    {
      text: 'User name',
      align: 'start',
      sortable: true,
      value: 'UserName'
    },
    {
      text: 'Purchase order',
      align: 'start',
      sortable: true,
      value: 'PO'
    },
    {
      text: 'Quantity',
      align: 'start',
      sortable: true,
      value: 'Quantity'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: false,
      value: 'Status'
    },
    {
      text: 'User name',
      align: 'start',
      sortable: true,
      value: 'UserName'
    }
  ];
  orderActivities: any[] = [];
  editingItemId: string = '';
  hideImage: boolean = true;
  /* Store Actions */
  /* Watchers */
  /* Data */

  async GetItemDetails(itemId: string) {
    const response = await ItemService.GetItemDetails(itemId);
    if (response.data) {
      this.itemDetail = response.data;
      let itemConversion = ConversionHelper.GetDisplayValues(
        this.itemDetail,
        this.itemInfos
      );
    }
  }

  chipDielineClick(i: number) {
    switch (i) {
      case 0:
        this.maskBuildType = 'trimmedlabel';
        return;
      case 1:
        this.maskBuildType = 'fullbleed';
        return;
      case 2:
        this.maskBuildType = 'safezone';
        return;
    }
  }

  async handleEditItem(itemId: string) {
    if (itemId) {
      this.editingItemId = itemId;
      await this.$store.dispatch('items/refreshItem', itemId);
      this.$store.dispatch('items/addProcessImageItemId', this.editingItemId);
    }
  }

  async getItemHistory(itemId: string) {
    const response = await ItemService.GetItemHistory(itemId);
    this.itemHistory = response.data;
  }

  async getOrderActivity(itemId: string) {
    const response = await OrdersService.GetOrderlinesByItemId(itemId);
    this.orderActivities = response.data;
  }
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  async mounted() {
    this.GetItemDetails(this.$route.params.id);
    this.getItemHistory(this.$route.params.id);
    this.getOrderActivity(this.$route.params.id);
  }
  /* Created */
}

import axios, { AxiosResponse } from 'axios';
import { handleResponse, handleErrorResponse, downloadFile } from '@/helpers/ApiHelper';
import {
  QuoteData,
  QuotesOrderModel,
  PricingMatrixModel
} from '@/store/models/QuotesModel';

const quoteUrl = `${process.env.VUE_APP_ROOT_API}/Quotes`;
// const quoteUrl = 'http://localhost:59410/api/Quotes';

export const QuoteService = {
  GetQuoteValues(model: PricingMatrixModel) {
    return axios.post(`${quoteUrl}/GetQuoteValues`, model);
  },
  GetTransparencyQuote(
    quantity: number,
    numberAcross: number,
    level: number,
    staticItemId: string
  ) {
    return axios.get(
      `${quoteUrl}/GetTransparencyQuote?quantity=${quantity}&numberAcross=${numberAcross}&level=${level}&staticItemId=${staticItemId}`
    );
  },
  async GetPricingMatrix(model: PricingMatrixModel) {
    // const response = await axios.post(`${quoteUrl}/GetPricingMatrix`, model);
    // return response.data;
    return axios.post(`${quoteUrl}/GetPricingMatrix`, model);
  },
  ProcessQuoteOrder(model: QuotesOrderModel) {
    return axios.post(`${quoteUrl}/ProcessQuoteOrder`, model);
  }
};

export class QuoteData {
  PublicId!: string;
  Quantity!: number;
  TotalCost!: number;
  DieId!: number;
  MaterialId!: number;
  FinishId!: number;
  ColorId!: number;
  SetupCost!: number;
  QuoteNumber!: number;
  CostEach!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.PublicId = rawItem.PublicId;
      this.Quantity = rawItem.Quantity;
      this.TotalCost = rawItem.TotalCost;
      this.DieId = rawItem.DieId;
      this.MaterialId = rawItem.MaterialId;
      this.FinishId = rawItem.FinishId;
      this.ColorId = rawItem.ColorId;
      this.QuoteNumber = rawItem.QuoteNumber;
      this.SetupCost = rawItem.SetupCost;
      this.CostEach = rawItem.CostEach;
    }
  }
}

export class QuotesOrderModel {
  DieId: number;
  MaterialId!: number;
  ColorId!: number;
  FinishId!: number;
  RollDirectionId!: number;
  IsANewDie!: boolean;
  DieHeight!: number;
  DieWidth!: number;
  DieShape!: string;
  RushOptionId!: string;
  Quantity!: number;
  CustomerId!: string;
  DueDate!: Date;
  TempFileId!: string;
  TempFileName!: string;
  IsHandApplied!: boolean;
  OrderPO!: string;
  ProofOptions!: ProofOptions;
  constructor(rawItem?: any) {
    this.DieId = rawItem.DieId;
    this.MaterialId = rawItem.MaterialId;
    this.ColorId = rawItem.ColorId;
    this.FinishId = rawItem.FinishId;
    this.RollDirectionId = rawItem.RollDirectionId;
    this.IsANewDie = rawItem.IsANewDie;
    this.DieHeight = rawItem.DieHeight;
    this.DieWidth = rawItem.DieWidth;
    this.DieShape = rawItem.DieShape;
    this.RushOptionId = rawItem.RushOptionId;
    this.Quantity = rawItem.Quantity;
    this.CustomerId = rawItem.CustomerId;
    this.DueDate = rawItem.DueDate;
    this.TempFileId = rawItem.TempFileId;
    this.TempFileName = rawItem.TempFileName;
    this.IsHandApplied = rawItem.IsHandApplied;
    this.OrderPO = rawItem.OrderPO;
    this.ProofOptions = rawItem.ProofOptions;
  }
}

export class ProofOptions {
  ProofQuantity!: number;
  IsLaminated!: boolean;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.ProofQuantity = rawItem.ProofQuantity;
      this.IsLaminated = rawItem.IsLaminated;
    }
  }
}

export class PricingMatrixModel {
  DieId!: number;
  MaterialId!: number;
  FinishId!: number;
  ColorId!: number;
  Quantity!: number;
  StartQuantity!: number;
  EndQuantity!: number;
  IncrementBy!: number;
  ShouldUseVarData!: boolean;
  ShouldUseVarnish!: boolean;
  DieHeight!: number;
  DieWidth!: number;
  DieShape!: string;
  constructor(rawItem?: any) {
    this.ShouldUseVarData = false;
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    this.DieId = rawItem.DieId;
    this.MaterialId = rawItem.MaterialId;
    this.FinishId = rawItem.FinishId;
    this.ColorId = rawItem.ColorId;
    this.Quantity = rawItem.Quantity;
    this.StartQuantity = rawItem.StartQuantity;
    this.EndQuantity = rawItem.EndQuantity;
    this.IncrementBy = rawItem.IncrementBy;
    this.ShouldUseVarData = rawItem.ShouldUseVarData;
    this.ShouldUseVarnish = rawItem.ShouldUseVarnish;
    this.DieHeight = rawItem.DieHeight;
    this.DieWidth = rawItem.DieWidth;
    this.DieShape = rawItem.DieShape;
  }
}

export interface QuotesState {
  quotes: QuoteData[];
  priceMatrix: QuoteData[];
  error: boolean;
  isLoading: boolean;
}


import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Color, Item, ItemInfo } from '@/store/models/ItemModel';
import J1lImg from '@/components/J1lImg.vue';
import DefaultLayout from '@/components/DefaultLayout.vue';
import ItemService from '@/services/item-service';
import HiRezImg from '@/components/HiRezImg.vue';
import AlertComponent from '@/components/AlertLayout.vue';
import { CookieHelpers } from '@/helpers/CookieHelper';
import { BuildItemAlertMessage } from '@/helpers/ObjectHelper';
import { AlertModel } from '@/store/models/DialogModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
@Component({
  components: { J1lImg, DefaultLayout, HiRezImg, AlertComponent }
})
export default class extends Vue {
  $refs!: {
    alertForMaterial: HTMLFormElement;
    contentAndQualityImg: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Action('ApproveItem', { namespace: 'items' })
  approveItem: any;
  @Getter('areDiesLoading', { namespace: 'dies' })
  isDiesLoading!: any;
  @Getter('dieTemplates', { namespace: 'dies' })
  dieTemplates!: any;
  /* Watchers */
  /* Data */
  currentStep: number = 1;
  isApproving: boolean = false;
  itemSpecificationApproval: boolean = false;
  trimLineApproval: boolean = false;
  onlinePressApproval: boolean = false;
  accurateInfoApproval: boolean = false;
  imageClarityApproval: boolean = false;
  textApproval: boolean = false;
  item: Item = new Item();
  alertOption!: AlertModel;
  isMaterialAlertActive: boolean = false;
  isOptoutOptionChecked: boolean = false;
  availableColors: any = [];
  maskBuildType: string = 'trimmedlabel';
  /* Methods */
  close() {
    if (this.$route.params.shouldClose) {
      window.close();
    } else {
      this.$router.back();
    }
  }
  canApprove() {
    return (
      this.onlinePressApproval &&
      this.accurateInfoApproval &&
      this.imageClarityApproval &&
      this.textApproval
    );
  }
  onApproveCutAndMotion() {
    this.currentStep = 3;
    window.scrollTo(0, 0);
  }
  showHighRez() {
    this.$refs.contentAndQualityImg.showHighRezDialog();
  }
  async onApproveClick() {
    this.isApproving = true;
    let model = {
      PartNumber: this.item.PartNumber,
      DieId: this.item.Die.Id,
      MaterialId: this.item.Material.Id,
      ColorId: this.item.Color.Id,
      FinishId: this.item.Finish.Id,
      Description: this.item.Description,
      ItemId: this.item.Id
    };
    await this.approveItem(this.item.Id);
    this.$store.dispatch('items/refreshItem', this.item.Id);
    this.$store.dispatch('orderlines/RefreshItemInOrderlines', this.item);

    this.isApproving = false;
    SetSnackBar('Item approved Successfully');
    if (this.$route.params.shouldClose) {
      window.close();
    } else {
      this.$router.back();
    }
  }
  /* Loaders */
  async loadItem(itemId: string) {
    const response = await ItemService.GetItemDetails(itemId);
    if (response.data) {
      const item = new Item(response.data);
      this.item = item;
    }
  }
  /* Mounted */
  async mounted() {}
  /* Created */
  async created() {
    if (this.$route.params.id) {
      this.loadItem(this.$route.params.id);
    }
  }
}
